import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { IMaskInput } from 'react-imask/esm/index';

import {
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputBaseComponentProps,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useStore } from 'effector-react';
import { Protocol } from '../api/protocol';
import { CurrentContractStore, CurrentParamsStore } from '../effector/dashboard';
import { PayonlineSettingsStore, SberbankSettingsStore } from '../effector/paymentSettings';
import { useDesignFeatures } from '../hooks/designFeatures';
import { balancePaymentMethod, BalancePaymentMethod, PaymentRequest } from '../types/balance';

import { apiPayonlineDoPay, apiSberbankDoPay } from '../api';

import { DEFAULT_PAYMENT_PRICE, MIN_PAYMENT_PRICE } from '../utils/constants';
import { buildPaymentRedirectUrl, firstContact } from '../utils/strings';
import { cleanPhone, emailMask, tel } from '../utils/tel';
import { formatErrorText } from '../utils/view';
import { isEmail, isInt, isPhone } from '../utils/guards';
import { redirect } from '../utils/query';

import { ButtonWithLoading } from './ButtonWithLoading';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 12,
        },
        textInput: {
            marginBottom: 12,
        },
        button: {
            width: '100%',
        },
        radios: {
            marginBottom: 8,
        },
        radioLabelWithDescription: {
            alignItems: 'flex-start',
        },
        radioInput: {
            paddingTop: 1,
        },
        radioLabel: {
            color: theme.palette.text.secondary,
            fontSize: 12,
        },
    })
);

const PhoneOrEmailMaskedInput: FC<InputBaseComponentProps> = (props) => (
    <IMaskInput
        {...props}
        mask={[
            {
                mask: tel.imask,
            },
            {
                mask: emailMask,
            },
        ]}
    />
);

type PaymentConfig = {
    defaultPaymentPrice: number;
    minPaymentPrice: number;
    maxPaymentPrice: number | null;
};

type PayonlineSums = Pick<Protocol.PayonlineSettingsResponse, 'minSumma' | 'maxSumma'>;
// копейки в рубли
const mapPaymentSettingsSumsCurrency = ({ minSumma, maxSumma }: PayonlineSums): PayonlineSums => ({
    minSumma: minSumma / 100,
    maxSumma: maxSumma / 100,
});

const getValidationSchema = ({ minPaymentPrice, maxPaymentPrice }: PaymentConfig) =>
    ({
        customerAddress: {
            required: true,
            validate: (phoneOrEmail: string) =>
                isPhone(phoneOrEmail) ||
                isEmail(phoneOrEmail) || (
                    <>
                        Телефон: {tel.maskHint}
                        <br />
                        E-mail: xxx@xxx.xx
                    </>
                ),
        },
        summa: {
            validate: (value: string) => {
                const minimumIsValid = Number(value) >= minPaymentPrice;
                if (maxPaymentPrice === null) {
                    return minimumIsValid || `Сумма должна быть не менее ${minPaymentPrice}₽`;
                }
                const maximumIsValid = Number(value) <= maxPaymentPrice;
                return (
                    (minimumIsValid && maximumIsValid) ||
                    `Сумма должна быть в промежутке от ${minPaymentPrice}₽ до ${maxPaymentPrice}₽`
                );
            },
            required: true,
        },
    }) as const;

type FormInputs = PaymentRequest & { paymentMethod: BalancePaymentMethod };

export const BalanceReplenishForm: FC = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);
    const { enqueueSnackbar } = useSnackbar();

    const sberbankSettings = useStore(SberbankSettingsStore);
    const payonlineSettings = useStore(PayonlineSettingsStore);

    const { enableSberbank = false, enablePayOnline = false } = currentContract || {};

    const defaultPaymentMethod: BalancePaymentMethod = enableSberbank ? 'sberbank' : 'payonline';

    const { register, handleSubmit, errors, setValue, clearError, watch } = useForm<FormInputs>({
        reValidateMode: 'onSubmit',
        defaultValues: {
            paymentMethod: defaultPaymentMethod,
            customerAddress: currentParams ? firstContact(currentParams.contactEmails, 'email') || '' : '',
        },
    });

    const currentPaymentMethod = watch('paymentMethod');

    const {
        paymentConfig: { defaultPaymentPrice, minPaymentPrice, maxPaymentPrice },
        text: paymentSettingsText,
        validationSchema,
    } = useMemo(() => {
        const paymentConfigByBalancePaymentMethod: Record<BalancePaymentMethod, Protocol.PaymentSettingsResponse> = {
            sberbank: sberbankSettings,
            payonline: payonlineSettings,
        };

        const { text, ...restPaymentSettings } = paymentConfigByBalancePaymentMethod[currentPaymentMethod];

        const { minSumma, maxSumma } = mapPaymentSettingsSumsCurrency(restPaymentSettings);

        const paymentConfig: PaymentConfig = {
            defaultPaymentPrice:
                minSumma <= DEFAULT_PAYMENT_PRICE && DEFAULT_PAYMENT_PRICE <= maxSumma
                    ? DEFAULT_PAYMENT_PRICE
                    : minSumma,
            minPaymentPrice: minSumma === 0 ? MIN_PAYMENT_PRICE : minSumma,
            maxPaymentPrice: maxSumma === 0 || maxSumma < minSumma ? null : maxSumma,
        };

        return { paymentConfig, text, validationSchema: getValidationSchema(paymentConfig) };
    }, [currentPaymentMethod, sberbankSettings, payonlineSettings]);

    const { displayDesignFeature } = useDesignFeatures();

    const handleAmountChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const { value } = event.target;
        if (isInt(value)) {
            setValue('summa', Number(value));
        }

        if (value.length > String(maxPaymentPrice).length) {
            setValue('summa', Number(value.slice(0, String(maxPaymentPrice).length)));
        }
    };

    const onSubmit = ({ paymentMethod, customerAddress, summa }: FormInputs): void => {
        const summaValidation = validationSchema.summa.validate(summa.toString());
        if (summaValidation !== true) {
            enqueueSnackbar(summaValidation, { variant: 'error' });
            return;
        }

        if (!currentContract) return;

        setLoading(true);

        const formattedPayload = {
            ...currentContract,
            customerAddress: isPhone(customerAddress) ? cleanPhone(customerAddress) : customerAddress,
            summa: Number(summa),
        };

        switch (paymentMethod) {
            case 'sberbank': {
                apiSberbankDoPay(formattedPayload)
                    .then((response) => {
                        enqueueSnackbar('Перенаправляем на платёжную страницу Сбербанка', {
                            variant: 'success',
                        });
                        redirect(response.result);
                    })
                    .catch((e) => {
                        enqueueSnackbar(e.message, { variant: 'error' });
                        setLoading(false);
                    });
                break;
            }
            case 'payonline': {
                apiPayonlineDoPay({
                    ...formattedPayload,
                    redirectURL: buildPaymentRedirectUrl(),
                })
                    .then((response) => {
                        enqueueSnackbar('Перенаправляем на платёжную страницу PayOnline', { variant: 'success' });
                        redirect(response.result);
                    })
                    .catch((e) => {
                        enqueueSnackbar(e.message, { variant: 'error' });
                        setLoading(false);
                    });
                break;
            }
            default: {
                enqueueSnackbar('Неверный способ оплаты', { variant: 'error' });
            }
        }
    };

    return (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)} onChange={() => clearError()}>
            {/* Email input */}
            <TextField
                id="balance-replenish-input-customerAddress"
                type="text"
                name="customerAddress"
                label="E-mail или телефон"
                disabled={loading}
                aria-invalid={errors.customerAddress ? 'true' : 'false'}
                helperText={formatErrorText(errors.customerAddress)}
                error={Boolean(errors.customerAddress && errors.customerAddress.message)}
                inputRef={register(validationSchema.customerAddress)}
                className={classes.textInput}
                InputProps={{
                    inputComponent: PhoneOrEmailMaskedInput,
                }}
            />

            {/* Number input */}
            <TextField
                id="balance-replenish-input-amount"
                type="number"
                name="summa"
                disabled={loading}
                aria-invalid={errors.summa ? 'true' : 'false'}
                helperText={formatErrorText(errors.summa) ?? paymentSettingsText}
                error={Boolean(errors.summa && errors.summa.message)}
                inputRef={register(validationSchema.summa)}
                className={classes.textInput}
                onChange={handleAmountChange}
                defaultValue={defaultPaymentPrice}
                InputProps={{
                    startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                    inputProps: {
                        max: maxPaymentPrice,
                        min: minPaymentPrice,
                    },
                }}
            />

            {/* Payment type */}
            <FormControl component="fieldset" className={classes.radios}>
                <RadioGroup name="paymentMethod" defaultValue={defaultPaymentMethod}>
                    {enableSberbank && (
                        <FormControlLabel
                            disabled={loading}
                            value={balancePaymentMethod.sberbank}
                            inputRef={register}
                            control={<Radio color="primary" />}
                            label="Оплата картой"
                        />
                    )}
                    {enablePayOnline && (
                        <FormControlLabel
                            disabled={loading}
                            value={balancePaymentMethod.payonline}
                            classes={{
                                root: classes.radioLabelWithDescription,
                            }}
                            inputRef={register}
                            control={<Radio color="primary" classes={{ root: classes.radioInput }} />}
                            label={
                                <div>
                                    <div>Другой способ</div>
                                    {displayDesignFeature(
                                        'main0_3_1',
                                        'QIWI, WebMoney, Яндекс.Деньги, Apple Pay, Google Pay',
                                        ({ name, caption }) => (
                                            <div id={name} className={classes.radioLabel}>
                                                {caption}
                                            </div>
                                        )
                                    )}
                                </div>
                            }
                        />
                    )}
                </RadioGroup>
            </FormControl>

            {/* Submit button */}
            <ButtonWithLoading type="submit" loading={loading} color="primary" noMargin className={classes.button}>
                Оплатить
            </ButtonWithLoading>
        </form>
    );
};
