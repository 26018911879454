export const tel = {
    imask: '+{7} (000) 000-00-00',
    reactInputMask: '+7 (999) 999-99-99',
    maskHint: '+7 (###) ###-##-##',
    // Lambda to avoid of following issue:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test#using_test_on_a_regex_with_the_global_flag
    regex: () => /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/g,
};

export const emailMask = /^\S*@?\S*$/;

export const formatPhone = (phone: string | null, fallback = 'Не указан'): string =>
    phone ? phone.replace(/^(?:\+7|7|8)(\d{3})(\d{3})(\d{2})(\d{2})/i, '+7 ($1) $2-$3-$4') : fallback;

export const cleanPhone = (phone: string | null): string => (phone ? phone.replace(/\D/g, '') : '');
